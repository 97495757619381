/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import "../assets/styles/styles.css";

import { useCookies } from "react-cookie";
import emailjs from "emailjs-com";
//emailjs.init("vg8d8CBmxlnQZxUjG");


import { useHistory } from "react-router-dom";

import ReactGA from "react-ga";
import { getAnalytics, logEvent } from "firebase/analytics";
import mobileBackground from "assets/img/bannerBGmobile.png";
import desktopImage from "assets/img/bannerBGdesktop.png";

export default function Index() {
  ReactGA.initialize("G-VDBYX7BGL8");

  const analytics = getAnalytics();

  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);
  const [underAge, setUnderAge] = useState(false);
  let history = useHistory();
  const myRef = useRef(null);
  const form = useRef();
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  emailjs.init(process.env.REACT_APP_PUBLIC_KEY);

  const backgroundImage = isMobile ? mobileBackground : desktopImage;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [messageSent, setMessageSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    setMessageSent(true);
    // emailjs.sendForm(
    //   process.env.REACT_APP_SERVICE_ID, // Your service ID from EmailJS
    //   process.env.REACT_APP_TEMPLATE_ID, // Your template ID from EmailJS
    //   form.current,                     // The form reference
    //   process.env.REACT_APP_PUBLIC_KEY  // Your public API key from EmailJS
    // )
    console.log(formData);
    emailjs
    .send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      {
        from_name: formData.name,
        message: `Phone: ${formData.phoneNumber}, Email: ${formData.email}, Message: ${formData.message}`,
        reply_to: formData.email,
      },
      emailjs.init(process.env.REACT_APP_PUBLIC_KEY)
    )
    .then((result) => {
      console.log('Message sent successfully!', result.text);
      setMessageSent(true);
    }, (error) => {
      console.log('Failed to send message:', error.text);
    });
  
    // Reset form data
    setFormData({
      name: '',
      email: '',
      phoneNumber: '',
      message: ''
    });
  };
  return (
    <>
    
    <div className="App">
      <header className="hero-section">
        <div className="header-overlay">
          <div className="header-top">
            <img src={require('assets/img/image0.png').default} alt="Brewery Acquisition Corp Logo" className="header-logo" />
          </div>
          <div className="header-top">
            <span className="header-text">Brewery Acquisition Corp.</span>
          </div>
          <div className="header-bottom">
            <h1>STRATEGIC ACQUISITIONS</h1>
          </div>
        </div>
      </header>

      <section className="content">
        <div className="column">
          <div className="section">
            <img src={require('assets/img/image9.png').default} alt="Strategic Capital" className="column-image" />
            <h3>STRATEGIC CAPITAL</h3>
            <p><strong>
              BAC IS PROVIDING STRATEGIC CAPITAL TO ACQUIRE EQUITY IN CRAFT BREWERIES LOCATED IN ONTARIO.
              </strong>
            </p>
            <p><strong>
              ALONG WITH CAPITAL INJECTION THAT WILL ALLOW CONTINUOUS GROWTH OF THE SELECTED BREWERIES, BAC IS ALSO ABLE TO FACILITATE DOWNSTREAM DEMAND THROUGH EXISTING RELATIONSHIPS AND ESTABLISHED DISTRIBUTION CHANNELS.
              </strong>
            </p>
          </div>
        </div>

        <div className="column">
          <div className="section">
            <img src={require('assets/img/image7.png').default} alt="Capital Growth" className="column-image" />
            <h3>EXPERIENCED TEAM</h3>
            <p><strong>BAC REPRESENTS A WELL CAPITALIZED AND AN EXPERIENCED MANAGEMENT TEAM.</strong></p>
            <p><strong>RAISED OVER $200M+ FOR OUR PORTFOLIO BRANDS AS WELL ESTABLISHED OVER 50 RETAIL OUTLETS IN THE RECENT YEAR.</strong></p>
            <p><strong>*THROUGH AN EFFICIENT MANAGEMENT PROCESSES, WE ARE ABLE TO FUND WITHIN 30-90 DAYS FROM THE LOI.</strong></p>
          </div>
        </div>

        <div className="column">
          <div className="section">
            <img src={require('assets/img/image8.png').default} alt="Brewery Equipment" className="column-image" />
            <h3>ACQUISITION</h3>
            <p><strong>LOOKING TO ACQUIRE ONTARIO CRAFT BREWERIES ONLY</strong></p>
            <p><strong>MUST PRODUCE LESS THAN 400,000 HL/YEAR</strong></p>
            <p><strong>YOU MUST HAVE WORKING-CONDITION EQUIPMENT AND READY TO BREW SYSTEMS</strong></p>
          </div>
        </div>
      </section>

      <section className="contact-section">
        <div className="contact-form-container">
          <div className="contact-header">
            <div className="heading-bar"></div>
            <h1>CONNECT WITH US</h1>
          </div>
          <div className="contact-content">
          <form ref={form} onSubmit={handleSubmit}>
  <label>Enter Your Name *</label>
  <input 
    type="text" 
    name="name" 
    placeholder="Name" 
    value={formData.name} 
    onChange={handleChange} 
    required 
  />

  <label>Enter Your Email *</label>
  <input 
    type="email" 
    name="email" 
    placeholder="Email" 
    value={formData.email} 
    onChange={handleChange} 
    required 
  />

  <label>Enter Your Phone Number</label>
  <input 
    type="text" 
    name="phoneNumber" 
    placeholder="PhoneNumber" 
    value={formData.phoneNumber} 
    onChange={handleChange} 
  />

  <label>Enter Your Message</label>
  <textarea 
    name="message" 
    placeholder="Message" 
    value={formData.message} 
    onChange={handleChange} 
  ></textarea>

  <button type="submit">Send Message</button>
  {messageSent && <p className="message-sent">Message Sent!</p>}
</form>

            <div className="contact-info">
              <p>IF YOU’D LIKE TO LEARN MORE, PLEASE FILL OUT THE CONTACT FORM OR SEND US AN EMAIL AT </p>
              <p>
                <a href="mailto:partner@bacorp.ca">partner@bacorp.ca</a><br />
              </p>
            </div>
          </div>
        </div>
      </section>

      <footer className="App-footer">
        <p>&copy; 2024 Brewery Acquisition Corp. All rights reserved.</p>
      </footer>
    </div>
          
    </>
  );
}
