import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import emailjs from "emailjs-com";

// import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// views without layouts


import Index from "views/Index.js";

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import ReactGA from "react-ga";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyA524MY5Tw_0S7tO1YF3xu8HDWgf7WcZpc",
  authDomain: "bacorp-83550.firebaseapp.com",
  projectId: "bacorp-83550",
  storageBucket: "bacorp-83550.appspot.com",
  messagingSenderId: "5611500856",
  appId: "1:5611500856:web:93201ac1d8904e92b5fdcd",
  measurementId: "G-QHS66B8MTM"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes without layouts */}
      
      {/* <Route path="/stores" exact component={Stores} />     */}
      {/* <Route path="/order" exact component={Order} />     */}
      {/* <Route path="/order" component={Order} />     */}
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
